:root {
  --nice-blue: #0d63a7;
  --bluish: #2376bb;
  --soft-blue: #57a8ef;
  --sky: #82c6ff;
  --METER_Blue: #338edc;
  --METER_green: #89e05d;
  --METER_orange: #ff975a;
  --METER_purple: #a37b9a;
  --white: #ffffff;
  --grey: rgba(255, 255, 255, 0.6);
  --black-87: rgba(0, 0, 0, 0.87);
  --Error: #db1f40;
  --Warning: #a0b10e;
  --state-danger: var(--Error);
  --room-bg-active: rgba(58, 234, 227, 0.5);
  --room-bg-inactive: #314153;
  --background-4: #1d2836;
  --background-2: #314153;
  --Background_2: rgba(255, 255, 255, 0.12);
  --Background_1: rgba(255, 255, 255, 0.38);
  --Plantscore_2: #ce4618;
  --Plantscore_3: #c08813;
  --Plantscore_4: #a0b10e;
  --Plantscore_5: #51a209;
  --Plantscore_6: #0b9205;
  --Plantscore_7: #028133;
  --batch_1: #3aeae3;
  --batch_2: #32d8ce;
  --batch_3: #2bc6ba;
  --batch_4: #23b4a6;
  --batch_5: #1ba293;
  --batch_6: #129180;
  --batch_7: #09806d;
  --batch_8: #00705b;
  --scroll-fg: rgba(51, 142, 220, 0.32);
  --scroll-bg: rgba(51, 142, 220, 0.12);
}

@font-face {
  font-family: 'Akkurat';
  src: url('https://phytochrome-assets.s3-us-west-2.amazonaws.com/fonts/lineto-akkurat-pro-regular.ttf') format('truetype'),
       url('https://phytochrome-assets.s3-us-west-2.amazonaws.com/fonts/lineto-akkurat-pro-regular.woff') format('woff'),
       url('https://phytochrome-assets.s3-us-west-2.amazonaws.com/fonts/lineto-akkurat-pro-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Akkurat Bold';
  src: url('https://phytochrome-assets.s3-us-west-2.amazonaws.com/fonts/lineto-akkurat-pro-bold.ttf') format('truetype'),
       url('https://phytochrome-assets.s3-us-west-2.amazonaws.com/fonts/lineto-akkurat-pro-bold.woff') format('woff'),
       url('https://phytochrome-assets.s3-us-west-2.amazonaws.com/fonts/lineto-akkurat-pro-bold.woff2') format('woff2');
}

#version {
  position: absolute;
  bottom: 0px;
  right: 10px;
  font-size: 12px;
  font-weight: normal;
}

html, body {
  color: white;
  min-height: 100%;
}

body {
  /* background-image: radial-gradient(circle at 0 0, #424b63, #0e2039); */
  background: radial-gradient(100% 100% at 0% 0%, #051232 0%, #121F3E 100%);
  font-family: 'Akkurat';
  overflow-y: scroll !important;
  padding-right: 0 !important;
}

#version {
  position: fixed;
  bottom: 2px;
  right: 4px;
  font-size: 12px;
  font-weight: normal;
}

#root, #wrapper {
  min-height: 100vh;
}

#wrapper.fullscreen {
  align-items: center;
  align-content: center;
}

fieldset {
  border: none;
}

#modalRoot > div {
  align-items: center;
  justify-content: center;

}

a { text-decoration: none; }
.hidden { display: none; }
h1 {
  font-family: 'Akkurat';
  font-size: 100.23px;
  letter-spacing: -1.5px;
  color: var(--white);
  margin: 0px;
}
h2 {
  font-family: 'Akkurat';
  font-size: 62.65px;
  letter-spacing: -0.5px;
  color: var(--white);
  margin: 0px;
}
h3 {
  font-family: 'Akkurat';
  font-size: 50.12px;
  letter-spacing: 0px;
  color: var(--white);
  margin: 0px;
}
h4 {
  font-family: 'Akkurat';
  font-size: 35.5px;
  letter-spacing: 0.25px;
  color: var(--white);
  margin: 0px;
}
h5 {
  font-family: 'Akkurat';
  font-size: 25.06px;
  letter-spacing: 0px;
  color: var(--white);
  margin: 0px;
}
h6 {
  font-family: 'Akkurat';
  font-size: 20.88px;
  letter-spacing: 0.5px;
  color: var(--white);
  margin: 0px;
}

.pointer { cursor: pointer; }
.linebreaksbr { white-space: pre-line; }

.mar-t-0 {
  margin-top: 0 !important;
}
.mar-b-0 {
  margin-bottom: 0 !important;
}
.mar-t-sxl {
  margin-top: 80px !important;
  margin-top: 5rem !important;
}
.mar-t-xl {
  margin-top: 40px !important;
  margin-top: 2.5rem !important;
}
.mar-t-lg {
  margin-top: 32px !important;
  margin-top: 2rem !important;
}
.mar-t-md {
  margin-top: 24px !important;
  margin-top: 1.5rem !important;
}
.mar-t-sm {
  margin-top: 16px !important;
  margin-top: 1rem !important;
}
.mar-t-xs {
  margin-top: 8px !important;
  margin-top: 0.5rem !important;
}
.mar-b-xl {
  margin-bottom: 40px !important;
  margin-bottom: 2.5rem !important;
}
.mar-b-lg {
  margin-bottom: 32px !important;
  margin-bottom: 2rem !important;
}
.mar-b-md {
  margin-bottom: 24px !important;
  margin-bottom: 1.5rem !important;
}
.mar-b-sm {
  margin-bottom: 16px !important;
  margin-bottom: 1rem !important;
}
.mar-b-xs {
  margin-bottom: 8px !important;
  margin-bottom: 0.5rem !important;
}

.pad-t-xl {
  padding-top: 40px !important;
  padding-top: 2.5rem !important;
}
.pad-t-lg {
  padding-top: 32px !important;
  padding-top: 2rem !important;
}
.pad-t-md {
  padding-top: 24px !important;
  padding-top: 1.5rem !important;
}
.pad-t-sm {
  padding-top: 16px !important;
  padding-top: 1rem !important;
}
.pad-t-xs {
  padding-top: 8px !important;
  padding-top: 0.5rem !important;
}
.pad-b-xl {
  padding-bottom: 40px !important;
  padding-bottom: 2.5rem !important;
}
.pad-b-lg {
  padding-bottom: 32px !important;
  padding-bottom: 2rem !important;
}
.pad-b-md {
  padding-bottom: 24px !important;
  padding-bottom: 1.5rem !important;
}
.pad-b-sm {
  padding-bottom: 16px !important;
  padding-bottom: 1rem !important;
}
.pad-b-xs {
  padding-bottom: 8px !important;
  padding-bottom: 0.5rem !important;
}

.alert {
  border-radius: .25rem;
  padding: 1rem;
}
.nowrap {
  white-space: nowrap;
}
.alert.alert-danger {
  background-color: var(--state-danger);
  color: var(--white);
  border: 1px solid var(--state-danger);
}
.alert.alert-success {
  background-color: var(--batch_8);
  color: var(--white);
  border: 1px solid var(--batch_8);
}
.text-size-md {
  font-size: 16px;
}
.text-white {
  color: var(--white) !important;
}
.text-primary {
  color: var(--METER_Blue) !important;
}
.text-danger {
  color: var(--Error) !important;
}
.text-warning {
  color: var(--Warning) !important;
}
.text-normal {
  font-family: 'Akkurat' !important;
}
.text-bold {
  font-family: 'Akkurat Bold' !important;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.input-hint {
  font-size: 12px;
  letter-spacing: 0.1px;
  margin-top: 5px;
}
.input-focused .input-hint {
  color: var(--METER_Blue);
}
.subtext {
  font-size: 12px;
  color: var(--Background_1);
}
p.subheader {
  font-family: 'Akkurat';
  font-size: 16.7px;
  letter-spacing: 0.5px;
  color: var(--white);
}

p {
  font-size: 14px;
  color: var(--grey);
  letter-spacing: 0.5px;
  line-height: 23px;
}

a:not([class]) {
  color: inherit !important;
}

.selected_room {
  background-color: rgba(27, 162, 147, 0.5) !important;
}

.button-inverse {
  font-family: 'Akkurat Bold';
  background-color: transparent;
  color: var(--METER_Blue);
  font-size: 14px;
  border: none;
}
.button-inverse:hover {
  background-color: rgba(51, 142, 220, 0.12);
  color: var(--METER_Blue);
}

.input-field .input-icon i {
  color: var(--METER_Blue) !important;
  left: 1em !important;
}

.input-field.input-focused .input-icon i {
  color: var(--white) !important;
}

.input-field {
  position: relative;
}

.input-field label {
  color: var(--METER_Blue);
  top: 0px;
  right: 0px;
}

.input-field .input-addon {
  background-color: var(--Background_2);
  border: none;
}

.input-field input {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid var(--METER_Blue);
  color: var(--white);
  padding-bottom: 0rem;
  font-family: 'Akkurat';
  border-radius: 6px 6px 0px 0px;
}

.input-field select {
  background-color: transparent;
  border: 1px solid var(--Background_2);
  color: var(--METER_Blue);
  text-transform: uppercase;
  font-size: 14px;
  font-family: 'Akkurat Bold';
  margin: 0 auto;
  height: 48px;
}

.input-field .input-icon input {
  padding-left: 3.5rem;
}

.input-field .input-icon input:focus {
  background-color: var(--Background_1);
  border: none;
  border-bottom: 2px solid var(--METER_Blue);
  color: var(--white);
}

.input-focused input::placeholder {
  color: #fff !important;
}

.input-field input[type=range] {
  background-color: transparent;
  border: none;
}

.input-field input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: var(--METER_Blue);
}

.input-field input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(0, 0, 0, 0.38);
}

.error-label {
  display: none;
}
.invalid-input .error-label {
  display: block;
  color: var(--Error);
}

.scrollbar {
  overflow-y: scroll;
}

.scrollbar-x {
  overflow-x: scroll;
}


::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: var(--scroll-bg);
  border-radius: 3px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--scroll-fg);
  border-radius: 3px;
},

.rt-card-cardTitle .rt-card-title {
  text-transform: uppercase;
  letter-spacing: -0.1rem;
  color: rgba(255, 255, 255, 0.38);
}
.rt-card-cardTitle.rt-card-large .rt-card-title {
  font-size: 1.4rem;
}

.rt-card-cardTitle > div {
  position: relative;
  width: 100%;
}

.rt-card-cardTitle .rt-button-button {
  position: absolute;
  top: -10px;
  right: 0px;
}

.rt-dropdown-values li {
  font-size: 1.2rem;
}

#login {
  max-width: 500px;
  margin: 0 auto;
}

/* Google maps tooltip bubble styling to remove white border */
#content .gm-style-iw-a > .gm-style-iw-t > .gm-style-iw-c {
  padding: 0 !important;
  background-color: var(--Background_2);
  box-shadow: 0 6px 12px 1px rgba(0,0,0,0.7);
}

#content .gm-style-iw-a > .gm-style-iw-t > .gm-style-iw-c > .gm-style-iw-d {
  overflow: hidden !important;
}

#content .gm-style-iw-a > .gm-style-iw-t::after {
  background: linear-gradient(45deg,rgba(49, 65, 83, 1) 50%,rgba(255,255,255,0) 51%,rgba(255,255,255,0) 100%) !important;
  box-shadow: 0 2px 7px 1px rgba(0,0,0,0.1);
}
